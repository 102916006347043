<template>
  <div class="welcome-footer">
    <p>Copyright &copy; 2019 - {{dayjs().format('YYYY')}} <a target="_blank" href="http://">fastlog.id</a> All Rights Reserved.</p>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  methods: {
    dayjs,
  },
};
</script>

<style lang="scss" scoped>
  .welcome-footer {
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>
